import React from 'react'
import {
  LazyLoadComponent,
  LazyLoadImage,
} from 'react-lazy-load-image-component'

import BannerHealthLogo from '../images/logos-row/Banner-health-logo-1.png'

import Intermountain from '../images/logos-row/intermountain-1.png'
import DignitiHealth from '../images/logos-row/dignity-health-1.png'
import Samgi from '../images/logos-row/samgi-logo-1.png'
import SutterHealth from '../images/logos-row/sutter-health-1.png'
import TexasHealth from '../images/logos-row/texas-health-1.png'
import Arizona from '../images/logos-row/arizona-1.png'
import PenState from '../images/logos-row/pen-state-1.png'

const LogoRowData = [
  {
    id: 1,
    img: Intermountain,
    alt: 'Intermountain',
  },
  {
    id: 2,
    img: Samgi,
    alt: 'Samgi',
  },
  {
    id: 3,
    img: Arizona,
    alt: 'Arizona',
  },
  {
    id: 4,
    img: BannerHealthLogo,
    alt: 'Banner Health',
  },
  {
    id: 5,
    img: PenState,
    alt: 'Pen State',
  },
  {
    id: 6,
    img: DignitiHealth,
    alt: 'Dignity Health',
  },
  {
    id: 7,
    img: SutterHealth,
    alt: 'Sutter Health',
  },
  // {
  //   id: 8,
  //   img: TexasHealth,
  //   alt: 'Texas Health',
  // },
]

console.log('LogoRowData', LogoRowData)

const LogoRow = () => {
  return (
    <div className="logos-row pb-5 col-lg-10 mx-auto px-0">
      <div className="row">
        {LogoRowData.map(logo => (
          <div className="col-lg-3 col-md-3 col-6 px-5 mx-md-auto mx-auto">
            {logo && (
              <LazyLoadImage
                className="img-fluid mt-3"
                height="auto"
                width="auto"
                src={logo?.img}
                // style={{ height: '55px' }}
                alt={logo?.alt}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default LogoRow
