import React from 'react'

import Swiper from 'react-id-swiper'
import {
  LazyLoadComponent,
  LazyLoadImage,
} from 'react-lazy-load-image-component'

import 'swiper/css/swiper.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

//images

import BannerHealthLogo from '../../images/new-homepage/Banner_Health_logo.png'
import UnityPointHealthLogo from '../../images/new-homepage/unitypoint-health.png'
import ValleyWiseHealthLogo from '../../images/new-homepage/valley-wise-health.jpg'
import CentralMaineHealthCareLogo from '../../images/new-homepage/central-maine-healthcare.png'
import SCHealthLogo from '../../images/new-homepage/scl-health.png'

// icons

import LeftArrowIcon from '../../images/new-homepage/left-arrow.svg'
import RightArrowIcon from '../../images/new-homepage/right-arrow.svg'

const TestimonialSwiper = () => {
  const ReviewSwiperData = [
    {
      testimony: `"This investment in my team has produced results beyond my expectations. Sandy is the most 
      effective executive coach I have worked with in over 40 years as a healthcare leader."`,
      img: ValleyWiseHealthLogo,
    },    
    {
      testimony: `"Our physicians now have the shared language, mental models, skills and tools - so when a sticky 
      situation come up, there's a team who works in-sync to tackle it.  Overall a very positive experience 
      that should continue to pay dividends now and over time."`,
      img: CentralMaineHealthCareLogo,
    },
    {
      testimony: `"Sandy’s presence immediately disarms even the most doubtful physician and allows for rapid,
        meaningful growth.<b> I would recommend her physician coaching program without hesitation.</b>"`,
      img: UnityPointHealthLogo,
    },
    {
      testimony: `"I have worked with Sandy in two different health systems. She connects with physicians and
      breaks through their guarded skepticism to unlock their true passion for patient care, empathy,
      and leadership. <b>The result is a resilient physician leader that can help to move the culture of the
      organization.</b>"`,
      img: SCHealthLogo,
    },
    {
      testimony: `"Many healthcare leaders, especially physicians, are skeptical of the value of coaching. Sandy
      cured my skepticism in just a few brief conversations. <b>I can say without reservation that she is the
      best coach I’ve encountered.</b> She was the driving force behind a highly successful program,
      where she helped transform physician leadership training."`,
      img: BannerHealthLogo,
    },
  ]

  const articleCarousel = {
    // loop: true,
    spaceBetween: 10,
    speed: 1000,
    // autoplay: {
    //   delay: 2000,
    // },
    autoplay: false,
    disableOnInteraction: false,
    navigation: {
      nextEl: '#article-next',
      prevEl: '#article-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true,
      clickable: true,
    },
    fadeEffect: {
      crossFade: true,
    },
    breakpoints: {
      1366: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      991.98: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 1,
      },
      767: {
        slidesPerView: 1,
      },
    },
  }
  return (
    <>
      <div className="testimonial-swiper">
        <div class="fullwidth-nav">
          <div class="fullwidth-nav-prev" id="article-prev">
            <img src={LeftArrowIcon} alt="Left Arrow" />
          </div>
          <div class="fullwidth-nav-next" id="article-next">
            <img src={RightArrowIcon} alt="Right Arrow" />
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 px-lg-4 px-md-4">
              <LazyLoadComponent>
                <Swiper {...articleCarousel}>
                  {ReviewSwiperData.map(review => (
                    <div className="h-auto p-1">
                      <div
                        className="container h-100 testimonial-container p-lg-4 p-md-5 p-4"
                        style={{ boxShadow: '0px 3px 14px rgb(0 0 0 / 10%)' }}
                      >
                        <div className="row h-100">
                          <div className="col-lg-12 align-self-center">
                            <p>
                              {/* "Sandy was the driving force behind a highly
                              successful leadership program at a large
                              integrated healthcare delivery system, where she
                              transformed physician leadership training." */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: review.testimony,
                                }}
                              />
                            </p>
                            <LazyLoadImage
                              alt="Testimonial Img"
                              className="img-fluid mt-3"
                              height="100%"
                              width="250px"
                              src={review.img}
                            />
                          </div>
                          {/* <div className="col-lg-8">
                           
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </Swiper>
              </LazyLoadComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestimonialSwiper
