import React from 'react'

import {
  LazyLoadComponent,
  //   LazyLoadImage,
} from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

//images

import slider1 from '../../images/hero/mentor-residents.svg'
import slider2 from '../../images/hero/be-with-family.svg'
import slider3 from '../../images/hero/fix-systems.svg'
import slider4 from '../../images/hero/be-yourself.svg'
import slider5 from '../../images/hero/lead-others.svg'

//swiper
import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

const HeroSwiper = () => {
  const HeroSwiperData = [
    {
      img: slider1,
    },
    {
      img: slider2,
    },
    {
      img: slider3,
    },
    {
      img: slider4,
    },
    {
      img: slider5,
    },
  ]

  const heroSlider = {
    loop: true,
    disableOnInteraction: false,
    direction: 'vertical',
    spaceBetween: 0,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
  }

  return (
    <>
      <div className="hero-swiper" style={{ width: '100%', height: '60px' }}>
        {/* <div className="container h-100 w-100"> */}
        {/* <div className="row h-100 w-100"> */}
        {/* <div class="fullwidth-nav d-none">
          <div class="fullwidth-nav-prev" id="article-prev">
            <img src={LeftArrowIcon} alt="Left Arrow" />
          </div>
          <div class="fullwidth-nav-next" id="article-next">
            <img src={RightArrowIcon} alt="Right Arrow" />
          </div>
        </div> */}
        <LazyLoadComponent>
          <Swiper {...heroSlider}>
            {HeroSwiperData.map(page => (
              <div
                className="swiper-slide w-100 text-left"
                style={{ height: '60px'}}
              >
                <div>
                  <img
                    className="img-fluid"
                    src={page.img}
                    alt="Book Page"
                    style={{
                      height: '60px',
                      objectFit: 'contain',
                      width: '100%',
                      zIndex: -1,
                      mixBlendMode: 'multiply',
                      position: 'relative',
                      top: '-8px'
                    }}
                  />
                </div>
              </div>
            ))}
          </Swiper>
        </LazyLoadComponent>
        {/* </div> */}
        {/* </div> */}
      </div>
    </>
  )
}

export default HeroSwiper
